<template>
  <!--begin::Card-->
  <!--  <div class="card">-->
  <!--    <div class="card-body">-->
  <!--      <RemoteTable :columns="table.columns"></RemoteTable>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="card">
    <!--begin::Card Body-->
    <div class="card-body">
      <ul class="nav nav-tabs nav-line-tabs mb-5 fs-5">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab" href="#section_1"
            >Section 1</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#section_2"
            >Section 2</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#section_3"
            >Section 3</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#section_4"
            >Section 4</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#section_5"
            >Section 5</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade active show" id="section_1" role="tabpanel">
          <div class="mb-10">
            <label class="form-label">App Title</label>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="App Title"
            />
          </div>

          <div class="mb-10">
            <label class="form-label">Description</label>
            <textarea
              class="form-control form-control-solid"
              rows="3"
            ></textarea>
          </div>
          <div class="mb-10">
            <label class="form-label">Video</label>
            <input
              type="file"
              class="form-control form-control-solid"
              rows="3"
            />
          </div>
        </div>
        <div class="tab-pane fade" id="section_2" role="tabpanel">
          <div class="mb-10">
            <label class="form-label">Title</label>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="Title"
            />
          </div>

          <div class="mb-10 row">
            <div class="col col-6">
              <label class="form-label">Description 1</label>
              <textarea
                class="form-control form-control-solid"
                rows="3"
              ></textarea>
            </div>
            <div class="col col-6">
              <label class="form-label">Description 2</label>
              <textarea
                class="form-control form-control-solid"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="mb-10 row">
            <div class="col col-4">
              <label class="form-label">Benefit 1</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Benefit 1"
              />
            </div>
            <div class="col col-4">
              <label class="form-label">Benefit 2</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Benefit 2"
              />
            </div>
            <div class="col col-4">
              <label class="form-label">Benefit 3</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Benefit 3"
              />
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="section_3" role="tabpanel">
          <div class="mb-10">
            <label class="form-label">Title</label>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="Title"
            />
          </div>

          <div class="mb-10 row">
            <div class="col col-4">
              <label class="form-label">Feature 1</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Feature 1"
              />
            </div>
            <div class="col col-4">
              <label class="form-label">Feature 2</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Feature 2"
              />
            </div>
            <div class="col col-4">
              <label class="form-label">Feature 3</label>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="Feature 3"
              />
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="section_4" role="tabpanel">
          <div class="mb-10">
            <label class="form-label">Title</label>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="Title"
            />
          </div>

          <div class="mb-10">
            <label class="form-label">Description</label>
            <textarea
              class="form-control form-control-solid"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "../core/helpers/breadcrumb";

export default {
  name: "LandingPage",
  // components: { RemoteTable },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Landing Page");
    });
  },
  data: function () {
    return {
      table: {
        columns: [
          {
            data: "aaa",
            label: "Test",
            width: "10",
          },
        ],
      },
      tableData: [
        {
          name: "Ahmed Ahmed",
          comment:
            "لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، ",

          review_date: "2016-05-03",
          rate: 5,
          order: 1,
        },
      ],
      currentPage4: 4,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`${val} items per page`);
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
    },
  },
};
</script>

<style scoped></style>
